<template>
  <div class="view-pmp-ad">
    <b-card>
      <div class="heading">
        <p>
          View Pmp Ad
        </p>
      </div>
      <!-- form -->
      <validation-observer ref="editPmpAd">
        <b-form
          class="mt-2"
          style="
              width: 100%"
          @submit.prevent
        >
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              text-bolder
              label="PMP Ad Name"
              label-for="blog-edit-title"
              class="mb-2 campaign-label"
            >
              <validation-provider
                v-slot="{ errors }"
                name="PMP Ad Name"
                rules="required"
              >
                <b-skeleton
                  v-if="isPmpAds"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="blog-edit-title"
                  v-model="postData.name"
                  placeholder="Please enter PMP Ad name here"
                  style="height: 42px"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            lg="12"
            class="d-flex mb-2"
          >
            <b-col
              md="6"
              lg="6"
            >
              <b-form-group
                label="Select Starting Date"
                label-for="blog-edit-category"
                class="mb-2 campaign-label"
              >
                <b-form-datepicker
                  v-model="postData.start_date"
                  :state="postData.start_date ? true : null"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              lg="6"
            >
              <b-form-group
                label="Select Ending Date"
                label-for="blog-edit-category"
                class="campaign-label"
              >
                <b-form-datepicker
                  v-model="postData.end_date"
                  :min="new Date(postData.start_date)"
                  :disabled="true"
                  :state="postData.start_date && postData.end_date ? new Date(postData.start_date) > new Date(postData.end_date) ? false : true : null"
                />
              </b-form-group>
            </b-col>
          </b-col>
          <b-col
            md="12"
            class="mx-auto"
          >
            <Calendar
              ref="calendar"
              v-model="date"
              :attributes="attributes"
              :min-date="minDates"
              is-expanded
            />
          </b-col>
          <div
            class="d-flex justify-content-center"
          >
            <b-button
              v-if="ifTimeSlotsExists"
              variant="outline-primary"
              class="my-1 mx-1"
              @click="editTimeForSelectedDates"
            >
              View Schedule
            </b-button>
          </div>
          <AddTimeSlotsInternalAdCampaigns
            ref="add-time-slots"
            :dates-for-time-slots="selectedDates"
            :array-of-dates-and-time-slots="finalDatesWithTimeSlots"
            @addDatesWithTimeSlotsToArray="addDatesWithTimeSlotsToArray"
            @notSetTimeSlot="notSetTimeSlot"
          />
          <viewListDatesWithTimeSlots
            ref="view-time-slots"
            :should-disable-cross="true"
            :array-of-dates-and-time-slots="finalDatesWithTimeSlots"
            @addDatesWithTimeSlotsToArrayAfterViewingList="addDatesWithTimeSlotsToArrayAfterViewingList"
          />
          <b-col
            md="9"
            lg="9"
            xl="9"
            class="mx-auto"
          >
            <div class="d-flex justify-content-center pr-4 pt-4 ">
              <h3 class="heading">
                Audience
              </h3>
            </div>
            <b-form-group
              text-bolder
              label="Location"
              class="label"
            >
              <validation-provider
                id="blog-map"
                name="Location"
              >
                <b-form-input
                  id="pac-input-edit-pmp-ad"
                  :value="postData.address"
                  placeholder="Search location here"
                  type="text"
                  autocomplete="off"
                  :disabled="true"
                />
                <b-row class="mt-1">
                  <b-col
                    md="6"
                    lg="6"
                    xl="6"
                  >
                    <b-form-group
                      text-bolder
                      label="Select Radius"
                      class="label"
                    >
                      <b-skeleton
                        v-if="isPmpAds"
                        type="input"
                      />
                      <v-select
                        v-else
                        v-model="postData.radius"
                        class="zindex-2"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        select-size="3"
                        label="label"
                        :disabled="true"
                        :reduce="radius => radius.id"
                        :clearable="false"
                        :options="options"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    lg="6"
                    xl="6"
                  >
                    <b-form-group
                      text-bolder
                      label="Select Venue Type"
                      class="label"
                    >
                      <v-select
                        v-model="venue_type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="venueList"
                        input-id="venue-type"
                        :disabled="true"
                        label="venue_type_name"
                        :reduce="selectVenueType => selectVenueType.id"
                        placeholder="Select Venue Type"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-provider>
              <b-row class="d-flex justify-content-center">
                <b-col
                  cols="9"
                >
                  <div class="mt-2 mx-4">
                    <b-button
                      variant="primary"
                      block
                      :disabled="true"
                      class="p-1 font-medium-2"
                    >
                      <div
                        v-if="addLocationSpinner"
                        class="spinner"
                      >
                        <b-spinner
                          small
                        />
                      </div>
                      Fetch Devices
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <br>
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
      <div class="map-div">
        <Map
          v-if="show"
          id="pac-input-edit-pmp-ad"
          ref="map-view-id-edit"
          :center-of-circle="postData.central_location"
          :radius="postData.radius"
          :selected-devices="selectedDeviceIds"
          :should-disable-options="true"
          @circleCenterSetFunc="circleCenterSetFunc"
          @circleRadiusSetFunc="circleRadiusSetFunc"
          @devicesGetLatLong="devicesGetLatLong"
          @locationAddressSetFunc="locationAddressSetFunc"
          @locationSelected="locationSelected"
          @locationRemoved="locationRemoved"
        />
        <span
          v-else
        >
          <b-skeleton-img
            class="map-div"
          />
        </span>
      </div>
      <div />
      <b-col
        md="12"
        class="mx-auto pt-2"
      >
        <b-card
          v-if="selectedDeviceIds.length"
          title="Selected Devices"
          class="mt-1 card2 p-1"
        >
          <span
            v-for="(item, index) in selectedDeviceIds"
            :key="index"
          >
            <b-badge
              variant="success"
              class="mr-2 mb-2 px-2 py-1 cursor-pointer"
            >
              <p
                class="text-id badge-class text-truncate"
                @click="goToMap(item)"
              >
                {{ item.name }}
              </p>
            </b-badge>
          </span>
        </b-card>
      </b-col>
      <AddedMediaToCampaign
        ref="add-media-cam-ref"
        :added-media="addedFilesToCampaign"
        :should-disable-cross="true"
        :max-time-limit="TimeLimitCondition.maxTargetAdsMediaCondition"
        @removeMediaFromList="deleteMediaFromPmpAd"
      />
      <div class="d-flex justify-content-center mt-0">
        <b-button
          variant="outline-primary"
          class="my-1"
          @click="reviewPmpAdsCampaign"
        >
          Review
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BCol, BFormGroup, BFormInput, BButton, BSpinner, BSkeleton, BSkeletonImg, BRow, VBTooltip,
  BBadge,
  BFormDatepicker,
} from 'bootstrap-vue'
// import _ from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import Vue from 'vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { Calendar } from 'v-calendar'
// import BannerAdsMedia from '@/components/common/BannerAdsMedia.vue'
import AddedMediaToCampaign from '@/components/common/AddedMediaToCampaign.vue'
import Map from '@/components/pmp-ads/pmpMap.vue'
import TimeLimitCondition from '@/common/config'
import store from '@/store'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import {
  getThreeDaysAheadDate,
  convertedDataComingFromSchedulerApi,
} from '@/common/global/calenderFunctions'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import AddTimeSlotsInternalAdCampaigns from '@/components/bannerads/AddTimeSlotsInernalAdCampains.vue'
import viewListDatesWithTimeSlots from '@/components/bannerads/viewListDatesWithTimeSlots.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'

Vue.prototype.moment = moment
export default {
  name: 'EditPmpAd',
  components: {
    Map,
    vSelect,
    BCard,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    AddedMediaToCampaign,
    BButton,
    BSkeleton,
    // BannerAdsMedia,
    BSpinner,
    BFormDatepicker,
    BSkeletonImg,
    BRow,
    BBadge,
    // validations
    ValidationProvider,
    ValidationObserver,
    Calendar,
    AddTimeSlotsInternalAdCampaigns,
    viewListDatesWithTimeSlots,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY h:mm a')
    },
    momentDateForLocalTime(date) {
      return moment.utc(`${date.date} ${date.time.HH}:${date.time.mm}`, 'YYYY-MM-DD HH:mm').local().format('HH:mm')
    },
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](MemberPermissions.PMP_ADS)
    const adminRole = store.getters['user/getUserRole']
    if (memberPermission || (adminRole === AccountTypes.ADMIN)) {
      next()
    } else {
      next({
        name: 'dashboard',
      })
    }
  },
  data() {
    return {
      show: false,
      TimeLimitCondition,
      isPmpAds: false,
      spinner: false,
      addLocationSpinner: false,
      postData: {
        name: null,
        PmpAdId: JSON.parse(this.$route.params.id),
        radius: 0,
        media_ids: [],
        device_ids: [],
        start_date: new Date(),
        end_date: new Date(),
        address: '',
        central_location: [],
      },
      devicesPaths: [],
      addedFilesToCampaign: [],
      currentTimeSpan: '',
      limit: '',
      totalDevices: 0,
      options: [
        { label: ' 5 Km', id: 5 },
        { label: '10 Km', id: 10 },
        { label: '15 Km', id: 15 },
        { label: '20 Km', id: 20 },
      ],
      number: {
        numeral: true,
      },
      // validation
      required,
      date: new Date(),
      minDates: null,
      ifTimeSlotsExists: false,
      disableSetTime: true,
      days: [],
      finalDatesWithTimeSlots: [],
      arrayOfDatesCollected: [],
      selectedDates: [],
      convertedDatesAndslots: [],
      venue_type: null,
      selectedDeviceIds: [],
    }
  },
  computed: {
    filteredDevices() {
      return this.allDevices.filter(device => !this.userSelectedDevices.map(res => res.id).includes(device.id))
    },
    dates() {
      return this.days.map(day => day.date)
    },
    attributes() {
      const currentAttributes = this.dates.map(date => ({
        highlight: true,
        dates: date,
      }))
      const previousAttributes = this.arrayOfDatesCollected.map(id => ({
        bar: 'red',
        dates: id,
      }))

      return [...currentAttributes, ...previousAttributes]
    },
    venueList() {
      return this.$store.getters['venues/getVenueTypeList']
    },
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },
  watch: {
    devicesPaths() {
      if (this.$refs['map-view-id-edit']) {
        this.$refs['map-view-id-edit'].devicesMarkerFunc(this.devicesPaths)
      }
    },
    'postData.radius': {
      handler(value) {
        if (this.$refs['map-view-id-edit']) {
          this.$refs['map-view-id-edit'].selectedRadiusFunc(value)
        }
      },
    },
  },
  async created() {
    await this.getPmpAds(this.$route.params.id)
    if (this.pmpads.pmp_ads_scheduler_has_device.length) {
      await this.mapDataToCalendar()
    }
  },
  methods: {
    dateChangeHandler() {
      this.devicesPaths = []
      this.totalDevices = 0
    },
    locationChangeHandler() {
      this.$refs['map-view-id-edit'].changeHandler()
      this.postData.central_location = {}
      this.postData.address = null
      this.postData.radius = 0
      this.totalDevices = 0
    },
    async getPmpAds(id) {
      try {
        this.isPmpAds = true
        let response = null
        if (this.isUserAdmin) {
          response = await this.$store.dispatch('pmpAds/getOnePmpAdByAdmin', { id, userHasGroupId: this.$route.params.userId })
        } else {
          response = await this.$store.dispatch('pmpAds/getOnePmpAdByUser', { id })
        }
        this.pmpads = response
        this.postData = {
          ...this.postData,
          name: this.pmpads.name,
          radius: this.pmpads.radius,
          central_location: this.pmpads.central_location,
          address: this.pmpads.address,
          start_date: this.pmpads.start_date,
          end_date: this.pmpads.end_date,
        }
        const devicesArray = this.pmpads.pmp_ads_has_device
        await this.parsedDevices(devicesArray)
        const array = (this.pmpads.central_location.slice(6, -1)).split(' ')
        this.totalDevices = this.pmpads.pmp_ads_has_device.length
        await this.getCircleCenterPoints(array)
        await this.$refs['map-view-id-edit'].updateAlreadyExistingDevices(this.selectedDeviceIds)
        await this.getSelectedDevicesByArea(devicesArray)
        this.addedFilesToCampaign = this.pmpads.pmp_ads_has_media.map(res => res.media)
        this.updateIdsOfMediaInPayload()
        this.isPmpAds = false
      } catch (error) {
        console.error('An error occurred:', error.response)
      }
    },
    getCircleCenterPoints(array) {
      const emptyArray = []

      for (let i = 0; i < array.length; i += 2) {
        // Ensure we have both latitude and longitude values
        if (array[i] && array[i + 1]) {
          emptyArray.push({
            lat: JSON.parse(array[i + 1]),
            lng: JSON.parse(array[i]),
          })
        }
      }

      if (emptyArray.length > 0) {
        this.show = true
      }

      // eslint-disable-next-line prefer-destructuring
      this.postData.central_location = emptyArray[0]
    },
    getSelectedDevicesByArea(array) {
      let locationPoint = ''
      const emptyArray = []
      this.devicesPaths = []
      // eslint-disable-next-line no-restricted-syntax
      for (const device of array) {
        locationPoint = device.device.location
        const points = (locationPoint.slice(6, -1)).split(',')
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < points.length; i++) {
          const subArray = points[i].split(' ')
          emptyArray.push({
            lat: JSON.parse(subArray[1]), lng: JSON.parse(subArray[0]), name: device.device.name, id: device.device.id,
          })
        }
      }
      this.devicesPaths = JSON.parse(JSON.stringify(emptyArray))
    },
    updateIdsOfMediaInPayload() {
      this.postData.media_ids = this.addedFilesToCampaign.map(res => res.id)
    },
    circleCenterSetFunc(center) {
      this.postData.central_location = center
    },
    circleRadiusSetFunc(radius) {
      this.postData.radius = Number(radius / 1000)
    },
    locationAddressSetFunc(address) {
      this.postData.address = address
    },
    devicesGetLatLong(array) {
      this.devicesPaths = []
      const paths = []
      array.forEach(data => {
        paths.push({
          lat: data.location.y, lng: data.location.x, name: data.name, id: data.id,
        })
      })
      this.devicesPaths = JSON.parse(JSON.stringify(paths))
      return this.devicesPaths
    },
    cancelPmpAds() {
      this.$router.push('/pmp-ads')
    },
    addMediaToPmpAd(media) {
      this.addedFilesToCampaign.push(media)
      this.updateIdsOfMediaInPayload()
    },
    deleteMediaFromPmpAd(index) {
      this.addedFilesToCampaign.splice(index, 1)
      this.updateIdsOfMediaInPayload()
    },
    async reviewPmpAdsCampaign() {
      if (this.addedFilesToCampaign) {
        await this.$store.dispatch('lightBox/lightBoxData', this.addedFilesToCampaign)
      }
    },
    setTimeForSelectedDates() {
      this.days.forEach(element => this.selectedDates.push(element.id))
      this.$refs['add-time-slots'].show()
    },
    async editTimeForSelectedDates() {
      this.setAllDatesAvailable()
      this.disableSetTime = true
      this.$refs['view-time-slots'].show()
    },
    addDatesWithTimeSlotsToArray(array) {
      this.days.forEach(element => this.arrayOfDatesCollected.push(element.id))
      this.days = []
      this.selectedDates = []
      this.disableSetTime = true
      this.setAllDatesAvailable()
      this.finalDatesWithTimeSlots = array
      if (this.finalDatesWithTimeSlots.length > 0) {
        this.ifTimeSlotsExists = true
      }
    },
    notSetTimeSlot() {
      this.setAllDatesAvailable()
      this.disableSetTime = true
    },
    setAllDatesAvailable() {
      this.days = []
      this.selectedDates = []
    },
    async addDatesWithTimeSlotsToArrayAfterViewingList(array) {
      this.arrayOfDatesCollected = []
      this.finalDatesWithTimeSlots = array
      await this.finalDatesWithTimeSlots.forEach(element => this.arrayOfDatesCollected.push(element.key))
      if (this.finalDatesWithTimeSlots.length === 0) {
        this.ifTimeSlotsExists = false
      }
    },
    async mapDataToCalendar() {
      this.pmpads.pmp_ads_scheduler_has_device.forEach(slots => {
        this.arrayOfDatesCollected.push(slots.start_date_time.split('T')[0])
      })
      this.postData.start_date = this.pmpads.start_date
      this.postData.end_date = this.pmpads.end_date
      const leastDate = this.arrayOfDatesCollected
        .map(date => new Date(date))
        .sort((a, b) => a - b)[0]
      this.$refs.calendar.move({ month: leastDate.getMonth() + 1, year: leastDate.getFullYear() })
      this.minDates = leastDate > getThreeDaysAheadDate() ? getThreeDaysAheadDate() : leastDate
      this.finalDatesWithTimeSlots = await convertedDataComingFromSchedulerApi(this.pmpads.pmp_ads_scheduler_has_device)
      this.ifTimeSlotsExists = true
    },
    locationSelected(device) {
      this.selectedDeviceIds.push(device)
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES', this.selectedDeviceIds)
    },
    locationRemoved(device) {
      this.selectedDeviceIds = this.selectedDeviceIds.filter(d => d.id !== device.id)
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES', this.selectedDeviceIds)
      if (this.$refs['map-view-id-edit']) {
        this.$refs['map-view-id-edit'].devicesMarkerFunc(this.devicesPaths)
      }
    },
    async parsedDevices() {
      this.selectedDeviceIds = this.pmpads.pmp_ads_has_device.map(deviceEntry => {
        const location = deviceEntry.device.location.match(/POINT\(([^ ]+) ([^ ]+)\)/)
        return {
          id: deviceEntry.device.id,
          name: deviceEntry.device.name,
          lat: parseFloat(location[2]),
          lng: parseFloat(location[1]),
        }
      })
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES', this.selectedDeviceIds)
    },
    deleteDevice(device, index) {
      this.selectedDeviceIds.splice(index, 1)
      this.$refs['map-view-id-edit'].markMarkerUselected(device)
    },
    goToMap(item) {
      this.$refs['map-view-id-edit'].moveToLocation(item.lat, item.lng)
    },
    async concatenateDateWithTimeSlots(array) {
      const temparray = []
      this.postData.scheduler = []
      array.forEach(element => {
        element.value.forEach(slots => {
          temparray.push({
            start_date_time: `${element.key} ${slots.start_time}`,
            end_date_time: `${element.key} ${slots.end_time}`,
          })
        })
      })
      this.postData.scheduler = this.postData.device_ids.flatMap(deviceID => temparray.map(schedule => ({
        ...schedule,
        device_id: deviceID,
      })))
    },
    getDeviceIDsBeforeSubmit() {
      this.postData.device_ids = this.selectedDeviceIds.map(device => device.id)
    },
  },
}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    .view-pmp-ad{
      #map {
        height: 100%;
      }
      .map-div{
        height: 400px !important;
      }
      .heading{
        font-size: 20px;
        line-height: 44px;
        color: #1f58b5;
        font-weight: 600;
        font-family: "Montserrat",serif;
        text-align: center;
      }
      .vs__search{
        height: 35px !important;
      }
      //.form-control{
      //  height: 42px !important;
      //}
      .b-skeleton-img{
        height: 400px !important;
      }
      .campaign-label{
        font-size: 13px;
        line-height: 18px;
        color: #323232;
        font-weight: 600;
        font-family: "Montserrat";
      }
      #calender {
        .dropdown-menu{
          z-index: 12 !important;
        }
      }
      #time{
        .vue__time-picker .controls .char{
          display: none !important;
        }
      }
    }
    .search1 {
      .vs__dropdown-menu {
        max-height: 150px !important;
        overflow-y: auto !important;
        width: 380px !important;
      }
      .vs__dropdown-toggle{
        max-height: 60px !important;
        overflow-y: auto !important;
        width: 380px !important;
      }
    }

    .is-disabled:focus{
      background-color: #ffffff !important;
    }
    .vc-bars{
      width: 20% !important;
    }
    .vc-day-content:focus {
      font-weight: normal !important;
    }
    .text-id{
      font-size: medium;
      max-width: 200px !important;
      margin-bottom: 0px !important;
    }
    .closediv2{
      position: relative;
      border-radius: 50%;
      bottom: 26px !important;
      left: 43px !important;
      top: auto;
      cursor: pointer;
    }
    .badgeSvg{
      height: 15px !important;
      width: 15px !important;
    }
    .btn-cross:hover{
      color: #ea5455 !important;
    }
    .card2{
      width: 100%;
      //height: 185px;
      border-radius: 10px;
      background-color: #eeeeee;
      margin-right: 41px;
      //overflow-y: scroll;
    }
    </style>
